import React from 'react';
import { Container } from '../components/ui/block/Container';
import { Layout } from '../components/ui/Layout';
import { Text } from '../components/ui/typography/Text';
import { Link } from 'gatsby';
import loadable from '@loadable/component';

const Helmet = loadable(() => import('react-helmet'));
const Header = loadable(() => import('../components/layout/Header'));
const Paper = loadable(() => import('../components/layout/Paper'));

export default () => (
	<Layout>
		<Helmet>
			<title>PageSpeed.cz - 404</title>
		</Helmet>
		<Header />
		<Paper>
			<Container className="u-text-center">
				<h2 className="u-color-blue">
					404
					<small className="u-block">Stránka nenalezena</small>
				</h2>
				<Text>
					<Link to="/" className="btn">
						<span className="btn__text">Zpět na úvodní stránku</span>
					</Link>
				</Text>
			</Container>
		</Paper>
	</Layout>
);
